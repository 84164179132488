import React from "react"
import PropTypes from "prop-types"
import Payment from "./Payment.js"
import LoginPayor from "./LoginPayor.js"
import PayorCreate from "./PayorCreate.js"
import Overlay from "./Overlay.js"
class ShowInvoice extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      invoice: null,
      loaded: false,
      messages: [],
      show_payor_status: 0,
      payor_code: null,
      invoice_payorlink_id: null,
      series_payorlink_id: null,
      show_remove_payorlink: false,
      show_payorlink_option: false
		};

    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.click_to_copy = this.click_to_copy.bind(this);
    this.toggle_url_passkey_inclusion = this.toggle_url_passkey_inclusion.bind(this);
    this.display_abn = this.display_abn.bind(this);
    this.save_invoice_payor = this.save_invoice_payor.bind(this);
    this.save_series_payor = this.save_series_payor.bind(this);
    this.remove_payorlink = this.remove_payorlink.bind(this);

    this.INVOICE_STATUS_TO_WORD = {'0':'INITIATE','1':'UNPAID','2':'PART PAID','3':'SUSPEND','4':'PAID','5':'PAID','6':'PAID'};
    this.INVOICE_STATUS_TO_TITLE = {'0':'Invoice Initiated','1':'Invoice Unpaid','2':'Invoice Partly Paid','3':'Invoice Suspended by User','4':'Invoice Paid','5':'Invoice Paid in Excess','6':'Invoice Paid by Other Source'};
    this.INVOICE_STATUS_TO_DESCRIPTION = {'0':'Invoice has been initiated but has not been opened yet to payment.',
      '1':'Invoice is unpaid and is awaiting payment.',
      '2':'Invoice has been partly paid, and is awaiting payment of the balance.',
      '3':'Invoice has been suspended by you and is not visible except to you.',
      '4':'Invoice has been paid.',
      '5':'Invoice has been paid in excess of what was owed.',
      '6':'Invoice has been paid by another source, not through PaySolve.'};
    this.INVOICE_STATUS_TO_COLOUR = {'0':'#040dba','1':'#040dba','2':'#e6a330','3':'#e6a330','4':'#32bfb8','5':'#32bfb8','6':'#32bfb8'};
    this.INVOICE_STATUS_TO_ADMIN_ACTION = {'1':'OPEN','2':'OPEN','3':'SUSPEND','6':'SET_AS_COMPLETE'};

  }

  componentDidMount(){
    fetch("/api/invoice/"+this.props.code+(this.props.passkey === null || this.props.passkey === undefined ? "" : "?pk="+this.props.passkey), {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Error in obtaining Invoice data.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if ([null,undefined].includes(json.invoice)){
        this.add_message("Encountered error obtaining information.",'danger');
        this.setState({
          loaded: true
        });
      } else {
        this.setState({
          invoice: json.invoice,
          loaded: true,
          payor_code: json.payor_code,
          invoice_payorlink_id: json.invoice_payorlink_id,
          series_payorlink_id: json.series_payorlink_id
        });
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    console.log(e);
    e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);
  }

  toggle_url_passkey_inclusion(){
    let invoice = this.state.invoice;
    if (invoice.short_url.includes('?pk')){
      invoice.short_url = invoice.short_url.split('?pk')[0];
    } else {
      invoice.short_url += ('?pk='+this.props.passkey);
    }
    this.setState({
      invoice: invoice
    });
  }

  display_abn(x){
    return x.split('').map((a,i) => [1,4,7].includes(i) ? a+' ' : a).join('');
  }

  save_invoice_payor(){
    fetch("/payorlink?objname=INV&invoice_id="+this.state.invoice.id, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({})*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.add_message("Saved Invoice to your Payor account.",'success');
      this.setState({
        invoice_payorlink_id: json.payorlink_id,
        show_payorlink_option: false
      });
    });
  }

  save_series_payor(){
    fetch("/payorlink?objname=SER&series_id="+this.state.invoice.series_id, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({})*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.add_message("Saved Series to your Payor account.",'success');
      this.setState({
        series_payorlink_id: json.payorlink_id,
        show_payorlink_option: false
      });
    });
  }

  remove_payorlink(object_name, payorlink_id){
    fetch("/payorlink?objname="+object_name+"&payorlink_id="+payorlink_id, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({})*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Erro in deleting Payor link.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      this.add_message("Successfully removed link to your Payor account.",'success');
      if (object_name === "INV"){
        this.setState({
          invoice_payorlink_id: null,
          show_remove_payorlink: false
        });
      } else if (object_name === "SER"){
        this.setState({
          series_payorlink_id: null,
          invoice_payorlink_id: null,
          show_remove_payorlink: false
        });
      }
    });
  }

  render () {
    console.log(this.state);
    return ( this.state.invoice === null ? 
      <div className="form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>

                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        {this.state.loaded ? null : 
          <div>
            <h2 style={{color: 'grey'}}>
              Invoice
            </h2>
            <p style={{fontSize: '14px'}}>
              Loading
            </p>
          </div>}
      </div> : 
      <div className="form-section">

        {this.state.show_payor_status > 0 ? 
          <Overlay on_cancel={() => this.setState({show_payor_status: 0})} title="Payor Account">
            {this.state.show_payor_status === 1 ? 
              <div>
                <LoginPayor auth_token={this.props.auth_token} />
                <div style={{textAlign: 'center'}}>
                  <p style={{color: 'grey', fontSize: '15px', paddingLeft: '20px', paddingRight: '20px'}}>
                    Don't have a Payor account? It takes 30 seconds to sign up, and you can save and manage all your bills in one place!
                  </p>
                  <button onClick={() => this.setState({show_payor_status: 2})} className="std-button">
                    Create Account
                  </button>
                </div>
              </div> : null}
            {[2,3].includes(this.state.show_payor_status) ? 
              <div>
                <PayorCreate auth_token={this.props.auth_token} on_complete={() => this.setState({show_payor_status: 3})} />
              </div> : null}
            {this.state.show_payor_status === 3 ?
              <div>
                <LoginPayor auth_token={this.props.auth_token} />
              </div> : null}
          </Overlay> : null}
        
        {this.state.show_payorlink_option ? 
          <Overlay on_cancel={() => this.setState({show_payorlink_option: false})} width="540px" title="Link to Payor Account">
            <div>
              <p style={{color: 'grey'}}>
                Do you want to link just this Invoice, or the whole Series, to your Payor account?
              </p>
              <table><tbody>
                <tr>
                  <td>
                    <button onClick={() => this.save_invoice_payor()} className="std-button">
                      Save Invoice
                    </button>
                  </td>
                  <td>
                    <button onClick={() => this.save_series_payor()} className="std-button">
                      Save Series
                    </button>
                  </td>
                  <td>
                    <button onClick={() => this.setState({show_payorlink_option: false})} className="std-button">
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}

        {this.state.show_remove_payorlink ? 
          <Overlay on_cancel={() => this.setState({show_remove_payorlink: false})} title="Remove Link">
            <div>
              <p style={{color: 'grey'}}>
                Are you sure you want to remove the link between this Invoice and your Payor account?
              </p>
              <table><tbody>
                <tr>
                  <td>
                    <button onClick={() => this.state.series_payorlink_id === null ? this.remove_payorlink("INV", this.state.invoice_payorlink_id) : this.remove_payorlink("SER", this.state.series_payorlink_id)} className="std-button">
                      Remove
                    </button>
                  </td>
                  <td>
                    <button onClick={() => this.setState({show_remove_payorlink: false})} className="std-button">
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              {/*<div style={{backgroundColor: this.state.invoice.amount_paid === 0 ? '#040dba' : (this.state.invoice.amount_paid === this.state.invoice.amount ? '#32bfb8' : '#e6a330'), color: this.state.invoice.amount_paid === 0 ? '#fff' : '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', fontFamily: 'Roboto Mono'}}>
                {this.state.invoice.amount_paid === 0 ? "UNPAID" : (this.state.invoice.amount === this.state.invoice.amount_paid ? "PAID" : "PART PAID")}
              </div>*/}
              <div style={{width: '100px'}}>
              <div style={{backgroundColor: this.INVOICE_STATUS_TO_COLOUR[this.state.invoice.status]}} className="status-box">
                {this.INVOICE_STATUS_TO_WORD[this.state.invoice.status]}
              </div>
              </div>
            </td>
            <td>
              <div style={{fontSize: '16px', color: 'grey', textAlign: 'right', margin: '5px', fontFamily: 'Roboto Mono'}}>
                {this.state.invoice.code}
              </div>
            </td>
          </tr>
        </tbody></table>
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>

                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        
        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h2>
                {this.state.invoice.title}
              </h2>
            </td>
            <td style={{textAlign: 'right'}}>
              <h2 style={{color: 'grey'}}>
                ${this.amount_to_formatted_string(this.state.invoice.amount)}
              </h2>
            </td>
          </tr>
        </tbody></table>
        
        <table style={{width: '100%'}}><tbody>
          <tr>
            <td style={{width: '75%', verticalAlign: 'top'}}>
        <table className="invoice-table"><tbody>
          <tr>
            <td>
              Organisation
            </td>
            <td>
              {this.state.invoice.company_name}
            </td>
          </tr>
          {this.state.invoice.company_abn === null ? null : 
          <tr>
            <td>
              ABN
            </td>
            <td>
              {this.display_abn(this.state.invoice.company_abn)}
            </td>
          </tr>}
          <tr>
            <td>
              Website
            </td>
            <td>
              <a href={this.state.invoice.company_website} style={{color: "grey", textDecoration: "underline"}} target="_blank">{this.state.invoice.company_website}</a>
            </td>
          </tr>
          <tr>
            <td>
              Amount
            </td>
            <td>
              <b>${this.amount_to_formatted_string(this.state.invoice.amount)}</b>
            </td>
          </tr>
          {this.state.invoice.series_id === null ? null : 
            <tr>
              <td>
                Series
              </td>
              <td style={{color: "#040dba", fontWeight: 'bold', cursor: 'pointer'}} onClick={() => {
                window.open(this.state.invoice.series_url,'_blank');
              }}>
                {this.state.invoice.series_title} <i className="fa-solid fa-arrow-up-right-from-square"></i>
              </td>
            </tr>}
          {this.state.invoice.has_due_date ? 
          <tr>
            <td>
              Due by
            </td>
            <td>
              {this.state.invoice.due_at.slice(5,16)}
            </td>
          </tr> : null}
          <tr>
            <td>
              Custom Code
            </td>
            <td>
              <span style={{fontFamily: 'Roboto Mono'}}>{[null,undefined].includes(this.state.invoice.custom_code) ? <span style={{color: 'grey'}}>N/a</span> : this.state.invoice.custom_code}</span>
            </td>
          </tr>
          <tr>
            <td>
              Short URL
            </td>
            <td>
              <span style={{fontFamily: 'Roboto Mono'}}>invc.me/{this.state.invoice.short_url}</span>
              <br /><span onClick={(e) => this.click_to_copy(e, 'invc.me/'+this.state.invoice.short_url)} className="copy-span">COPY</span>
              {this.props.passkey === null ? null : <span onClick={this.toggle_url_passkey_inclusion} className="copy-span">W/{this.state.invoice.short_url.includes('?pk') ? 'O' : null} PASSKEY</span>}
            </td>
          </tr>
          <tr>
            <td>
              Description
            </td>
            <td>
              {this.state.invoice.description}
            </td>
          </tr>
          <tr>
            <td>
              Date accrued
            </td>
            <td>
              {this.state.invoice.date_accrued_at.slice(5,16)}
            </td>
          </tr>
          <tr>
            <td>
              Date due
            </td>
            <td style={{color: this.state.invoice.is_late ? "red" : "normal"}}>
              {this.state.invoice.due_at === null ? null : this.state.invoice.due_at.slice(5,16)}
            </td>
          </tr>
        </tbody></table>
            </td>
            <td style={{verticalAlign: 'top', textAlign: 'center'}}>
              <div style={{margin: 'auto', textAlign: 'center'}} dangerouslySetInnerHTML={{__html: this.state.invoice.short_url.includes('?pk') ? this.props.qrcode_pk : this.props.qrcode}}>

              </div>
              <br />
              {this.state.invoice_payorlink_id === null && this.state.series_payorlink_id === null ? 
                <button onClick={() => this.state.payor_code === null ? this.setState({show_payor_status: 1}) : (this.state.invoice.series_id === null ? this.save_invoice_payor() : this.setState({show_payorlink_option: true}))} className="std-button">
                  <i className="fa-solid fa-cloud-arrow-down"></i> Save Invoice
                </button> : 
                <button className="std-button" onClick={() => this.setState({show_remove_payorlink: true})}>
                  <i className="fa-solid fa-check"></i> Saved
                </button>}
              <br />
            </td>
          </tr>
        </tbody></table>

        <div className="invoice-tally" style={{marginTop: '40px'}}>
          <table><tbody>
            <tr>
              <td>
                Charged
              </td>
              <td>
                {this.amount_to_formatted_string(this.state.invoice.amount)}
              </td>
            </tr>
            <tr>
              <td>
                Paid
              </td>
              <td>
                -{this.amount_to_formatted_string(this.state.invoice.amount_paid)}
              </td>
            </tr>
            <tr>
              <td>
                Paid (other source)
              </td>
              <td>
                -{this.amount_to_formatted_string(this.state.invoice.amount_paid_by_other_source)}
              </td>
            </tr>
            <tr>
              <td>
                Balance owing
              </td>
              <td style={{borderTop: 'solid', borderTopWidth: '1px', borderColor: '#c9c9c9'}}>
                {this.amount_to_formatted_string(this.state.invoice.amount - this.state.invoice.amount_paid - this.state.invoice.amount_paid_by_other_source)}
              </td>
            </tr>
          </tbody></table>
        </div>

        {this.state.invoice.amount === this.state.invoice.amount_paid ? 
          <div style={{marginTop: '40px', textAlign: 'center'}}>
            <h3>
              This Invoice has been paid.
            </h3>
          </div> : 
          <div style={{marginTop: '40px'}}>
            <h3>
              Pay this Invoice
            </h3>
            <div className="flash flash-info">
              <table><tbody>
                <tr>
                  <td>
                    <i className="fa-solid fa-circle-info"></i>
                  </td>
                  <td>
                    For a first-time transaction to this account, your payment may take up to 24 hours to process.
                  </td>
                </tr>
              </tbody></table>
            </div>
            <Payment code={this.props.code} passkey={this.props.passkey} auth_token={this.props.auth_token} />
          </div>}
      </div>
    );
  }
}

export default ShowInvoice
